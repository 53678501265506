<template>
    <vs-card class="h-full pl-1">
      <!-- Header -->
      <div slot="header" class="flex w-full">
        <h3>Promotions</h3>
        <vs-button class="ml-auto w-24" @click="save"> Save </vs-button>
      </div>
  
      <!-- Products -->
      <h4>Shop Products</h4>
      <vs-input
        label="product slug"
        class="mt-5 md:w-1/2 w-full"
        v-model="products_slug"
      />
  
      <vs-divider />
  
      <!-- Cards -->
      <div class="" v-for="(card, key) in cards" :key="key">
        <h4>Card {{ key + 1 }}</h4>
        <vs-input
          label="Title"
          class="mt-5 md:w-1/2 w-full"
          v-model="card.title"
        />
        <vs-input
          label="Description"
          class="mt-4 md:w-1/2 w-full"
          v-model="card.description"
        />
        <vs-input
          label="Button Text"
          class="mt-4 md:w-1/2 w-full"
          v-model="card.button_text"
        />
        <vs-input
          label="Button Link"
          class="mt-4 mb-3 md:w-1/2 w-full"
          v-model="card.link"
        />
        <!-- Desktop Image -->
        <label class="text-sm ml-1 mt-4">Desktop Image</label>
        <br />
        <div v-if="card.desktop_image_url">
          <img :src="card.desktop_image_url" alt class="w-full h-auto max-w-md" />
          <br />
  
          <vs-button
            class="bg-primary2 my-2"
            @click="handleRemoveImage('desktop', card)"
            >Remove Image</vs-button
          >
        </div>
        <div v-else>
          <input
            type="file"
            @change="handleFileChange($event, 'desktop', card)"
            accept="image/*"
          />
        </div>
        <br />
        <!-- Mobile Image -->
        <label class="text-sm ml-1 mt-3">Mobile Image</label>
        <br />
        <div v-if="card.mobile_image_url">
          <img :src="card.mobile_image_url" alt class="w-full h-auto max-w-md" />
          <br />
          <vs-button
            class="bg-primary2 my-2"
            @click="handleRemoveImage('mobile', card)"
            >Remove Image</vs-button
          >
        </div>
        <div v-else>
          <input
            type="file"
            @change="handleFileChange($event, 'mobile', card)"
            accept="image/*"
          />
        </div>
        <legend>The square brackets <code>[ ]</code> in the URL, such as <code>[nurse]</code>, are placeholders that will be automatically replaced with the appropriate user type (e.g., <code>nurse</code>, <code>doctor</code>) when processed.</legend>
        <vs-divider v-if="key < 4" />
      </div>
    </vs-card>
  </template>
  
  <script>
  import { mapActions } from "vuex";
  import axios from "@/axios";
  
  export default {
    data() {
      return {
        products_slug: "",
        cards: [],
      };
    },
  
    methods: {
      ...mapActions("eCommerce", ["saveJsonSettings", "getJsonSettings"]),
      save() {
        this.$vs.loading({
          text: "Saving...",
          color: "#3dc9b3",
          background: "white",
        });
        const slug = "promotions";
        const value = { products_slug: this.products_slug };
        this.cards.forEach((item, index) => {
          value[`card_${index + 1}`] = item;
        });
        this.saveJsonSettings({ value, slug })
          .then((val) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Success",
              text: "Settings Saved.",
              color: "success",
            });
          })
          .catch((err) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Failed to save settings.",
              text: "Please try again later.",
              color: "danger",
            });
            console.error(err);
          });
            this.$vs.loading.close();
  
      },
      async handleRemoveImage(imgType, card) {
        if (imgType === "desktop") card.desktop_image_url = "";
        if (imgType === "mobile") card.mobile_image_url = "";
      },
      uploadImage(event, imgType, card, key = "profileImage") {
        this.$vs.loading({
          text: "Uploading...",
          color: "#3dc9b3",
          background: "white",
        });
        let data = new FormData();
        data.append("profileImage", event);
        let config = {
          header: {
            "Content-Type": "image/png",
          },
        };
        axios
          .post("/api/v1/doctors/upload?key=" + key, data, config)
          .then((res) => {
            if (imgType === "desktop") card.desktop_image_url = res.data.Location;
            if (imgType === "mobile") card.mobile_image_url = res.data.Location;
            this.$vs.loading.close();
          })
          .catch((err) => {
            this.$vs.loading.close();
            if (err && err.status === 415) {
              this.$vs.notify({
                title: "Upload Failed",
                text: "File Format Not Supported",
                color: "danger",
              });
            } else {
              this.$vs.notify({
                title: "Upload Failed",
                text: "Please try again later.",
                color: "danger",
              });
            }
          });
      },
      handleFileChange(event, imgType, card) {
        this.uploadImage(event.target.files[0], imgType, card);
      },
    },
    async created() {
      this.getJsonSettings({ slug: "promotions" }).then((val) => {
        this.cards = Object.values(val.data.data).filter((card) => card.title); // Filter out non-card data
        this.products_slug = val.data.data.products_slug;
      });
    },
  };
  </script>